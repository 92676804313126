@import 'src/master';

.checkmarkStudent {
	display: flex;

	height: 50px;
	width: 50px;
	margin: 10px;

	&.hoverable:hover {
		-webkit-filter: drop-shadow(3px 3px 2px #000);
		filter: drop-shadow(2px 2px 1px #000);
	}

	&.green {
		//background-color: $color-green;
	}
	&.yellow {
		//background-color: $color-yellow;
	}
	&.red {
		//background-color: $color-red;
	}
	.white {
		color: #00000080 !important;
	}

	.happy {
		width: 120%;
		height: 120%;
		margin: -50px;
		background: url('3stars.png');
		background-size: 70px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.normal {
		width: 120%;
		height: 120%;
		margin: -50px;
		background: url('2stars.png');
		background-size: 70px;
		background-position: center;
		background-repeat: no-repeat;
	}
	.unhappy {
		width: 120%;
		height: 120%;
		margin: -50px;
		background: url('star.png');
		background-size: 40px;
		background-position: center;
		background-repeat: no-repeat;
	}

	align-items: center;
	justify-content: space-around;

	&.checked {
		.happy {
			background: url('3starsShadow.png') no-repeat;
			background-size: 70px;
			background-position: center;
			background-repeat: no-repeat;
		}
		.normal {
			background: url('2starsShadow.png') no-repeat;
			background-size: 70px;
			background-position: center;
			background-repeat: no-repeat;
		}
		.unhappy {
			background: url('starShadow.png') no-repeat;
			background-size: 40px;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

@media all and (max-width: 750px) {
	.checkmarkStudent {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		&.green {
			background-color: $color-green;
		}
		&.yellow {
			background-color: $color-yellow;
		}
		&.red {
			background-color: $color-red;
		}
		.checkedMark {
			display: block;
			height: 50px;
			width: 50px;
		}
	}
}
