@import 'src/master';

.openQuestion {
	width: 100%;
	display: flex;
	justify-content: center;

	.cardContainer {
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
		text-align: left;
		width: 100%;

		textarea {
			margin-top: 10px;
		}
	}
	.openQuestionTextWrapper {
		border-color: $turkoosi !important;
		:focus {
			border-color: $turkoosi !important;
		}
	}
}

@media all and (max-width: 750px) {
	.openQuestion {
		width: 100%;
		display: flex;

		justify-content: center;
		align-items: center;
		flex-direction: column;

		.numberWrapper {
			min-width: 50px;
			font-family: 'Gerbera-light';

			color: $AAturkoosi;
			text-align: center;
			margin-right: 5px;
		}

		.cardContainer {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			padding: 2vh 0 5vh 0;
			width: 90%;
			margin-top: 20px;
			min-height: 30vh;
			&.adult {
				display: flex;
				flex-grow: 3;
			}
			.openTextAnswer {
				font-family: Gerbera-light;
				font-size: 1em;
				padding: 15px 10px 0 10px;
			}

			textarea {
				min-height: 85%;
				font-size: 18px;
				align-self: center;
			}
		}
		.openQuestionTextWrapper {
			border-color: $turkoosi !important;
			padding-top: 10px;
			:focus {
				border-color: $turkoosi !important;
			}
		}

		.buttonContainer {
			margin-top: 20px;
			display: flex;
			width: 100%;
			justify-content: space-evenly;

			.swipeButton {
				width: 100px;
			}
		}
	}
}
