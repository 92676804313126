@import 'src/master';

.neuvokas-frontpage {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: $color-background-main;
	.frontpage-image {
		max-height: 30vh;
		max-width: 50vw;

		-webkit-filter: drop-shadow(5px 5px 5px #333);
		filter: drop-shadow(5px 5px 5px #333);
	}

	.neuvokasLogo {
		max-height: 25vh;
		max-width: 20vw;
		margin-bottom: 50px;
	}

	.frontpageButton {
		margin-top: 50px;
	}
	.sydanliittoLogo {
		position: fixed;
		bottom: 40px;
		right: 40px;
		width: 200px;
	}
	.osaText {
		position: fixed;
		bottom: 40px;
		left: 40px;
		color: white;

		font-size: 1.2em !important;
		font-family: Gerbera-medium !important;
		font-weight: normal !important;
		font-style: normal !important;
	}
}
@media all and (max-width: 1200px) {
	.neuvokas-frontpage {
		.neuvokasLogo {
			max-width: 250px;
			margin-bottom: 30px;
		}
		.frontpage-image {
			max-width: 300px;
		}
		.osaText {
			display: none;
		}
	}
}
@media all and (max-width: 750px) {
	.neuvokas-frontpage {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;

		background-color: $color-background-main;
		.frontpage-image {
			max-height: 30%;
			max-width: 50%;

			-webkit-filter: drop-shadow(5px 5px 5px #333);
			filter: drop-shadow(5px 5px 5px #333);
		}

		.neuvokasLogo {
			max-height: 25%;
			max-width: 50%;

			margin-bottom: 0;
		}

		.frontpageButton {
			margin-top: 0;
			margin-bottom: 10vh;
		}

		.osaText {
			display: none;
		}
		.sydanliittoLogo {
			position: fixed;

			width: 100px;
			bottom: 10px;
			right: 10px;
		}
	}
}
