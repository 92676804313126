@import 'src/master';

.cardSelection {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.cardSelectionImage {
		width: 75%;

		margin-bottom: 50px;
	}

	.neuvokasText {
		width: 70%;
		text-align: left;
		margin-bottom: 40px;
	}
	.buttonContainer {
		width: 70%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		.backButton {
			margin-top: 20px;
			background-color: #966d5b !important;
			width: 200px;
			height: 53px;
			line-height: 37px;
			text-align: center;
			.arrowBackIcon {
				transform: scale(0.9);
			}
		}
		.buttonWrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			.neuvokasButton {
				width: 250px !important;
				margin: 10px;
			}
		}
	}
}

@media all and (max-width: 750px) {
	.cardSelection {
		padding: 0 0 20px 0;
		display: block;

		.cardSelectionImage {
			width: 100%;
			margin-bottom: 50px;
		}

		.neuvokasText {
			width: 85vw;
			text-align: left;
			margin: auto;
			margin-bottom: 40px;
		}
		.buttonContainer {
			margin: auto;

			padding-left: 0vw;
			width: 70vw;
			display: block;
			flex-direction: column;
			justify-content: flex-start;
			.backButton {
				margin-top: 20px;
				background-color: #966d5b !important;
				width: 200px;
				height: 53px;
				line-height: 37px;
				text-align: center;
				.arrowBackIcon {
					transform: scale(0.9);
				}
			}
			.buttonWrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				margin: auto;
				.neuvokasButton {
					width: 250px !important;
					margin: 10px;
				}
			}
		}
	}
}
