@import 'src/master';

.infoDialog {
	min-width: 500px;
	max-width: 800px;
	margin: auto;

	.dialogTitleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.dialogTitleText {
			font-family: Gerbera-medium;
			font-size: 1.2em;
			margin: 20px;
			margin-left: 40px;
		}
	}
	.instructionsWrapper {
		padding: 0 40px 40px 40px;
	}
}

@media all and (max-width: 750px) {
	.infoDialog {
		min-width: 200px;
		padding: 0;
		margin: 0;

		.dialogTitleWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.dialogTitleText {
				font-family: Gerbera-medium;
				font-size: 1.2em;
				margin: 20px;
			}
		}

		.dialogPaperWrapper {
			padding: 0;
			margin: 5%;
			display: block;
		}
		.instructionsWrapper {
			padding: 0px 20px 20px 20px;
		}
	}
}
