@import 'src/master';
.extraInfoDialog {
	padding: 0;
	margin: 0;
	#simple-dialog-title {
		padding-top: 0;
	}

	.dialogTitleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.dialogTitleText {
			font-family: Gerbera-medium;
			font-size: 1.2em;
			margin: 20px;
			margin-left: 40px;
		}
	}

	.infoWrapper {
		min-width: 550px;
		min-height: 400px;
		display: flex;
		padding: 0px 40px 40px 40px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.infoContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.kuva {
			width: 50vw;
		}
		.title {
			font-family: Gerbera-medium;
			margin-bottom: 10px;
		}
		.startImage {
			height: 250px;
			width: auto;
		}
		.infoTextWrapper {
			margin-top: 40px;
			font-size: 1.1em;
		}

		.infoLinkWrapper {
			margin-top: 20px;
		}
		a {
			color: $AAturkoosi;
			font-family: Gerbera-light;
			font-size: 1.2em;
		}
	}
}
@media all and (max-width: 750px) {
	.extraInfoDialog {
		min-width: unset;
		.dialogPaperWrapper {
			min-width: 60%;
			padding: 0;
			margin: 5%;
			display: block;
		}

		.dialogTitleWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.dialogTitleText {
				font-family: Gerbera-medium;
				font-size: 1em;
				margin: 20px;
				margin-left: 20px;
			}
		}

		.infoWrapper {
			min-width: unset;
			min-height: unset;
			display: flex;
			padding: 20px;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.kuva {
				width: 80vw;
			}
			.title {
				font-family: Gerbera-medium;
				margin-bottom: 10px;
			}
			.startImage {
				height: auto;
				width: 60vw;
			}
			.infoTextWrapper {
				margin-top: 40px;
				font-size: 1.1em;
			}

			.infoLinkWrapper {
				margin-top: 20px;
			}
			a {
				color: $AAturkoosi;
				font-family: Gerbera-light;
				font-size: 1.1em;
			}
		}
	}
}
