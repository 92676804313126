@import 'src/master';
.accessabilityModalDialog {
	min-width: 200px;
	padding: 0;
	margin: 0;
	#simple-dialog-title {
		padding-top: 0;
	}
	.dialogTitleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.dialogTitleText {
			font-family: Gerbera-medium;
			font-size: 1.2em;
			margin: 20px;
			margin-left: 40px;
		}
	}
	.accessabilityTextWrapper {
		display: flex;
		padding: 20px 40px 40px 40px;
		flex-direction: column;
		justify-content: center;
		align-items: left;
		max-width: 900px;
		.cookieMainTitle {
			font-size: 1.3em;
			font-family: Gerbera-medium;
			margin-bottom: 10px;
		}
		.cookieTitle {
			font-family: Gerbera-medium;
			margin-bottom: 10px;
			margin-top: 10px;
		}
		.startImage {
			width: 200px;
			height: auto;
		}
		.infoTextWrapper {
			margin-top: 30px;
		}

		.infoLinkWrapper {
			margin-top: 20px;
		}
	}
}
@media all and (max-width: 750px) {
	.accessabilityModalDialog {
		min-width: unset;

		.dialogTitleWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.dialogTitleText {
				font-family: Gerbera-medium;
				font-size: 1em;
				margin: 20px;
				margin-left: 20px;
			}
		}
		.dialogPaperWrapper {
			min-width: 60%;
			padding: 0;
			margin: 5%;
			display: block;
		}
		.accessabilityTextWrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: left;
			padding: 20px;
			max-width: 900px;
			.cookieMainTitle {
				font-size: 1.3em;
				font-family: Gerbera-medium;
				margin-bottom: 10px;
			}
			.cookieTitle {
				font-family: Gerbera-medium;
				margin-bottom: 10px;
				margin-top: 10px;
			}
			.startImage {
				width: 200px;
				height: auto;
			}
			.infoTextWrapper {
				margin-top: 30px;
			}

			.infoLinkWrapper {
				margin-top: 20px;
			}
		}
	}
}
