@import 'src/master';

.schoolHero {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.neuvokasOtsikko {
		font-family: Gerbera-bold;
		font-size: 1.1em;
		margin-top: 20px;
		color: $AAturkoosi;
	}

	.neuvokasText {
		width: 70%;
		text-align: left;
		margin-bottom: 30px;
	}

	.videoIframe {
		margin: 20px 40px 40px 40px;
		width: 70%;
		height: 400px;
	}
	.buttonWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		.backButton {
			background-color: #966d5b !important;

			text-align: center;
			margin-right: 10px;
		}
		.forwardButton {
			margin-left: 10px;
		}
	}
}

@media all and (max-width: 750px) {
	.schoolHero {
		padding: 20px 20px 40px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.neuvokasOtsikko {
			font-family: Gerbera-bold;
			font-size: 1.1em;
			margin-top: 20px;
			color: $AAturkoosi;
		}

		.neuvokasText {
			width: 90vw;
			text-align: left;
			margin-bottom: 30px;
		}

		.videoIframe {
			width: 90vw;
			height: 200px;
		}
		.buttonWrapper {
			width: 90vw;
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			button {
				margin-top: 10px;
				width: 70vw !important;
			}
			.backButton {
				background-color: #966d5b !important;

				text-align: center;
				margin-right: 0px;
			}
			.forwardButton {
				margin-left: 0px;
			}
		}
	}
}
