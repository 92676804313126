@import 'src/master';

.sidebarMaterial {
	background-color: #00a2c5 !important;
}

.wrapperWrapper {
	overflow: none;
	height: 100%;
	.topbarwrapper {
		width: 100%;
		height: 60px;
	}
	.main-view {
		height: calc(100% - 60px);
		padding-top: 60px;
		width: 100%;
		margin: 0;
		overflow-y: scroll;

		.flexWrapper {
			display: flex;
			width: 100%;
			.questionnaireFlexWrapper {
				max-width: 1000px;
				order: 2;
				flex: 3 0;
				padding-top: 40px;
				padding-bottom: 100px;
				background-color: $color-background-page;
			}
			.leftSideWrapper {
				order: 1;
				flex: 1;
				flex-shrink: 2;
				background-color: $color-background-page;
			}
			.rightSideWrapper {
				order: 3;
				flex: 1;

				justify-content: flex-start;
				padding-left: 20px;
				padding-top: 40px;
				background-color: $color-background-page;
			}
		}
	}

	@media all and (max-width: 1200px) {
		.leftSideWrapper,
		.rightSideWrapper {
			display: none;
		}
		.questionnaireFlexWrapper {
			padding: 20px !important;
			padding-bottom: 80px !important;
		}
		.questionnaireWrapWrapper {
			width: 100%;
			height: calc(100% - 60px);
			margin-top: 0;
			padding-top: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}
	}

	@media all and (max-width: 750px) {
		.topBarWrapper {
			width: 100%;
			height: calc(var(--vh, 1vh) * 8);
		}
		.main-view {
			padding-top: 0;
			height: calc(100% - calc(var(--vh, 1vh) * 8));
		}

		.questionnaireWrapWrapper {
			width: 100%;
			height: 100%;
			margin-top: 0;
			padding-top: 0;
			display: block;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}
	}

	background-color: $color-background-page;

	.frontpage-image {
		max-width: 400px;
	}
	.frontpage-headline {
		margin-top: 50px;
		font-size: 1.5em;
		color: white;
		font-weight: 500;
	}

	.frontpage-button {
		margin-top: 50px;
		height: 50px;
		width: 250px;
		font-size: 20px;
		background-color: $color-button-primary;
		&:hover,
		&:active {
			background-color: $color-button-primary;
		}
	}
}
