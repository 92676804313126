@import 'src/master';

.schoolResults {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.neuvokasOtsikko {
		font-family: Gerbera-bold;
		font-size: 1.1em;
		margin-top: 20px;
		color: $AAturkoosi;
	}

	.buttonWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		.backButton {
			background-color: #966d5b !important;

			text-align: center;
			margin-right: 10px;
		}
	}
	.neuvokasText {
		width: 70%;
		text-align: left;
		margin-bottom: 40px;
	}
	.summaryStarContainer {
		height: 300px;
		width: 300px;
		margin-top: 20px;
		margin-bottom: 60px;
		.summaryStar {
			position: relative;
			width: 100%;
			height: 100%;
			background-image: url('summaryStarYellow.svg');
			background-size: 300px;
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.8em;

			z-index: 88;
		}
		.starCountWrapper {
			border-radius: 50%;
			top: -260px;
			position: relative;
			left: calc(50% - 100px);
			height: 200px;
			width: 200px;
			background-color: yellow;
			z-index: 3;
		}
	}

	.openAnswerList {
		margin-top: 30px;
		margin-bottom: 60px;
		width: 80%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		align-content: flex-start;
		.openAnswerTextWrapper {
			display: flex;
			flex-direction: column;
			margin: 0 20px 0 20px;

			.questionItem {
				text-align: left !important;
			}
			.questionAnswer {
				margin-left: 20px;
				text-align: left !important;
			}
		}
	}
}

@media all and (max-width: 750px) {
	.schoolResults {
		padding: 20px 20px 40px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.neuvokasOtsikko {
			font-family: Gerbera-bold;
			font-size: 1.1em;
			margin-top: 20px;
			color: $AAturkoosi;
		}

		.buttonWrapper {
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-around;
			align-items: center;
			margin-top: 10px;
			.backButton {
				background-color: #966d5b !important;
				margin-top: 20px;
				text-align: center;
				margin-right: 0;
			}
		}

		.neuvokasText {
			width: 70%;
			text-align: left;
			margin-bottom: 40px;
		}
		.summaryStarContainer {
			height: 200px;
			width: 100%;
			margin-top: 20px;
			margin-bottom: 0px;
			.summaryStar {
				position: relative;
				width: 50vw;
				height: 100%;
				background-image: url('summaryStarYellow.svg');
				background-size: 50vw;
				background-repeat: no-repeat;
				background-position: center center;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.8em;

				z-index: 88;
			}
			.starCountWrapper {
				border-radius: 50%;

				position: relative;
				top: -80%;
				left: calc(50% - 60px);
				height: 120px;
				width: 120px;
				background-color: yellow;
				z-index: 3;
			}
		}

		.openAnswerList {
			margin-top: 30px;
			margin-bottom: 20px;
			width: 100%;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: flex-start;
			align-content: flex-start;
			.neuvokasOtsikko {
				margin-bottom: 10px;
			}
			.openAnswerTextWrapper {
				display: flex;
				flex-direction: column;
				margin: 0 20px 0 20px;

				.questionItem {
					text-align: left !important;
				}
				.questionAnswer {
					margin-left: 20px;
					text-align: left !important;
				}
			}
		}
	}
}

#printSchoolPdf {
	position: fixed;

	bottom: -4000px;
	width: 210mm;

	.fullHeadline {
		font-family: gerbera-medium;
		font-size: 8mm;
		height: 11mm;
		padding: 1mm 0 2mm 0;
	}
	.ageAndName {
		margin: 1mm 5mm 3.5mm 5mm;
		font-size: 5mm;
		display: flex;
		justify-content: space-around;
	}

	.answerList {
		margin: 0 6mm 0mm 6mm;

		.sectionNameWrapper {
			padding-top: 5mm;
			height: 6mm;
		}
	}

	.summaryStarContainer {
		height: 88mm;
		width: 88mm;
		margin-bottom: 0px;
		margin: auto;
		margin-top: 11mm;

		.summaryStar {
			position: relative;
			width: 88mm;
			height: 88mm;
			background-image: url('summaryStarYellow.svg');
			background-size: 88mm;
			background-repeat: no-repeat;
			background-position: center center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.8em;

			z-index: 88;
		}
		.starCountWrapper {
			border-radius: 50%;

			position: relative;
			top: -77mm;
			left: 9mm;
			height: 66mm;
			width: 66mm;
			background-color: yellow;
			z-index: 3;
		}
	}

	.listWrapper {
		.colorText {
			height: 6mm;
			font-family: Gerbera-medium;
			font-size: 6mm;
			padding: 0;
			padding-top: 5mm;
		}
	}

	.adultNameWrapper {
		padding-top: 5mm;
		height: 6mm;
	}
	li {
		padding: 0;
	}
	.questionTextContainer {
		font-size: 4mm;
		padding: 2mm;
		height: 7mm;
		text-align: left;
	}
	.followUpContainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: 11mm;
		.questionText {
			margin-right: 20px;
		}
	}

	.openAnswerList {
		display: flex;
		justify-items: flex-start;
		align-items: flex-start;
		margin: 0 5mm 5mm 5mm;
		font-size: 5mm;
		padding: 0;
		padding-top: 11mm;
	}
	.openQuestionSectionTitle {
		height: 11mm;
		margin: 0;
	}
	.openAnswerTextWrapper {
		display: flex;
		flex-direction: column;
		margin: 0 20px 0 20px;

		.questionItem {
			height: 11mm;
			font-weight: bold;
			text-align: left !important;
		}
		.questionAnswer {
			height: 11mm;

			margin-left: 20px;
			text-align: left !important;
		}
	}

	.logoWrapper {
		display: flex;

		justify-content: space-between;
		padding: 11mm 5mm 0 5mm;
		.logoLeft {
			width: auto;
			height: 11mm;
		}
		.logoRight {
			width: auto;
			height: 11mm;
		}
	}
}
