@import 'src/master';

.choiceQuestion {
	.followUpQuestionWrapper {
		background-color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 55px;
		padding: 0 10px 0 10px;
		margin-top: 5px;
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-topright: 10px;
		-moz-border-radius-bottomright: 10px;
		-moz-border-radius-bottomleft: 10px;

		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-webkit-border-bottom-left-radius: 10px;

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);
		.followUpQuestion {
			padding: 5px;
		}
		.followUpTextField {
			padding: 5px;
			flex-grow: 1;
			:after {
				border-bottom-color: $turkoosi !important;
			}
		}
	}
	.multipleFollowUpQuestionWrapper {
		background-color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 55px;
		padding: 0 10px 0 10px;
		margin-top: 5px;
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-topright: 10px;
		-moz-border-radius-bottomright: 10px;
		-moz-border-radius-bottomleft: 10px;

		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-webkit-border-bottom-left-radius: 10px;

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);
		.multipleFollowUpQuestion {
			padding: 5px;
		}
		.multipleFollowUpTextField {
			padding: 5px;
			flex-grow: 1;
			:after {
				border-bottom-color: $turkoosi !important;
			}
		}
	}
	.cardContainer {
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-topright: 10px;
		-moz-border-radius-bottomright: 10px;
		-moz-border-radius-bottomleft: 10px;

		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-webkit-border-bottom-left-radius: 10px;

		background-color: white;
		min-height: 42px;

		padding: 5px 0px 5px 10px;
		margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);

		.numberWrapper {
			min-width: 50px;
			font-family: 'Gerbera-light';

			color: $AAturkoosi;
			text-align: center;
			margin-right: 5px;
		}

		.questionTextWrapper {
			width: 60%;
			text-align: left;
		}

		.radioWrapper {
			display: flex;
			.multipleRadioContainer {
				padding: 0 5px 0 0;
				display: flex;
				flex-direction: row;
				.radioContainerWrapper {
					display: flex;
					flex-direction: row;

					.radioDivider {
						align-self: center;
						height: 30px;
						width: 1px;
						font-size: 25px;
						border-left: 1px solid black;
						padding: 0;
						margin-left: 8px;
					}
				}
				.radioDivider {
					align-self: center;
					padding: 0 5px 0 5px;

					font-size: 25px;
				}
			}
		}

		.questionMark {
			font-family: 'Gerbera-medium';
			font-size: 25px;
			color: $saavutettavaTurkoosi;
			height: 30px;
			width: 30px;
		}

		.questionMarkButton {
			padding: 3px;
			margin-left: 5px;
		}
	}
}

@media all and (max-width: 750px) {
	.choiceQuestion {
		display: flex;
		justify-content: center;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
		align-self: flex-start;
		&.adult {
			flex-grow: 3;
		}
		.cardContainer {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			text-align: left;
			padding: 2vh 0 5vh 0;
			width: 90%;
			margin-top: 20px;
			min-height: 30vh;

			&.adult {
				display: flex;
				flex-grow: 3;
			}

			.questionTextWrapper {
				width: 90%;
				margin-top: 35px;
				text-align: left;
				color: $color-basic-text;
			}

			.radioWrapper {
				display: flex;
				margin-top: 20px;
				flex-grow: 3;
				align-items: flex-end;

				.multipleRadioContainer {
					padding: 0 5px 0 0;
					display: flex;
					flex-direction: column;
					align-items: center;

					.adultNameWrapper {
						padding-right: 6px;
						font-size: 0.9em !important;
						font-family: Gerbera-light;

						max-width: 100px;
					}
					.radioContainerWrapper {
						display: flex;
						align-items: center;
						.radioDivider {
							align-self: center;
							height: 90px;
							width: 1px;
							font-size: 25px;
							border-left: 1px solid black;
							padding: 0;
							margin-left: 8px;
						}
					}
				}
			}
		}
		.buttonContainer {
			display: flex;
			width: 100%;
			justify-content: space-evenly;
			background-color: red !important;

			.swipeButton {
				width: 100px;
			}
		}
	}
}
