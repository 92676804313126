@import 'src/master';

.colorInfo {
	.cardContainer {
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-topright: 10px;
		-moz-border-radius-bottomright: 10px;
		-moz-border-radius-bottomleft: 10px;

		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-webkit-border-bottom-left-radius: 10px;

		background-color: white;
		height: 30px;
		padding: 10px;

		margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);

		.radioWrapper {
			margin-right: 10px;
		}
	}
	.colorTitleWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: 15px;
		font-weight: bold;
		.ball {
			margin-right: 6px;
			width: 30px;
			height: 30px;
		}
	}
	.colorDescription {
		display: flex;
		align-self: flex-start;
		align-content: flex-start;
		text-align: left;
		justify-content: flex-start;
		margin-left: 45px;
	}
}
