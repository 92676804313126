@import 'src/master';
.materialDrawer {
	background-color: #00a2c5;
}
.sideBarWrapper {
	height: 100%;
	background-color: #00a2c5;
	.sideBar {
		overflow: auto;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		height: 100%;

		min-width: 270px;
		background-color: $AAturkoosi;

		.drawerLogo {
			width: 70%;
			max-width: 200px;
			align-self: center;
			margin-top: 5%;
			-webkit-filter: drop-shadow(2px 2px 2px #333);
			filter: drop-shadow(2px 2px 2px #333);
		}
		.logoWrapper {
			display: flex;
			flex-direction: column;

			.neuvokasLogo {
				align-self: center;
				width: 70%;
				max-width: 200px;
				height: auto;
				margin-bottom: 6%;
			}
		}

		.navigationListDivider {
			background-color: #ffffff80;
		}
	}
	@media all and (max-width: 750px) {
		.sideBar {
			height: 100%;
			font-size: 2em !important;
			padding: 0 0 0 0;
			.navigationList {
				margin-left: 10px;
			}
			.drawerLogo {
				display: hidden;
				width: auto;
				max-height: 20vh;
				align-self: center;
				margin-top: 5px;
				margin-bottom: 10px;
			}
			.logoWrapper {
				display: flex;
				flex-direction: column;
				.neuvokasLogo {
					align-self: center;
					width: auto;
					margin-top: 5px;
					max-width: 40vw;
					max-height: 20vh;
				}
			}
		}
		@media all and (max-height: 650px) {
			.drawerLogo {
				display: none;
			}
		}
	}
}
