@import 'src/master';

.instructions {
	.neuvokasText {
		text-align: left;
	}
	.colorInfoWrapper {
		margin-bottom: 30px;
	}
}
