@import 'src/master';

.startInfoView {
	width: 100%;

	padding-top: 80px;
	padding-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.startImage {
		margin-bottom: 60px;
		height: auto;
		width: 70%;
	}

	.instructionsWrapper {
		width: 70%;
	}

	.buttonWrapper {
		align-self: flex-end;
		width: 100%;
		padding: 60px 0 20px 0;
		button {
			width: 200px;
		}
	}
}

@media all and (max-width: 750px) {
	.startInfoView {
		display: block;
		padding-top: 0px;
		padding-bottom: 0px;
		width: 100%;
		background-color: white;
		min-height: 100%;
		.instructionsWrapper {
			padding: 20px 0 0 0;
			width: 100%;
		}
		.neuvokasText {
			padding: 0 20px 0 20px;
		}

		.colorContainer {
			margin: 0px 0px 0px 0px !important;
		}

		.buttonWrapper {
			padding: 20px 0 20px 0;
		}
	}
}
