@import 'src/master';

.smileyInfo {
	padding: 10px 10px 0 10px;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.starRow {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

@media all and (max-width: 750px) {
	.smileyInfo {
		padding: 10px 10px 0 10px;
		margin: 0;
		display: block;
		flex-direction: column;
		align-items: flex-start;
		.starRow {
			display: flex;
			width: 100%;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
}
