@import 'src/master';

.cardSave {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;

	align-items: center;
	.descriptionWrapper {
		width: 70%;
		text-align: left;
	}
	.neuvokasButtonWrapper {
		margin-top: 50px;

		.backAndSave {
			display: flex;
		}
		.backButton {
			background-color: #966d5b !important;

			height: 53px;
			line-height: 37px;
			text-align: center;
			.arrowBackIcon {
				transform: scale(0.9);
			}
		}
		.saveButton,
		.cardSelectionButton {
			width: 200px;
		}

		.saveButton {
			background-color: #00747a !important;
		}
	}

	.cardSaveImage {
		width: 400px;
		height: auto;
		margin-bottom: 50px;
	}
}

@media all and (max-width: 1200px) {
	.cardSave {
		padding: 10px;
	}
}

@media all and (max-width: 750px) {
	.cardSave {
		padding: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		background-color: white;
		.descriptionWrapper {
			padding-top: 20px;
			width: 90%;
			text-align: left;
		}
		.neuvokasButtonWrapper {
			width: 90%;
			display: flex;
			flex-direction: column-reverse;
			align-items: flex-end;
			padding: 0px 0 20px 0;

			.backAndSave {
				display: flex;
				margin-top: 10px;
			}
			.backButton {
				min-height: 53px;
				line-height: 37px;
				text-align: center;
				margin-right: 5vw;
				.arrowBackIcon {
					transform: scale(0.9);
				}
			}
			.saveButton,
			.cardSelectionButton {
				width: 200px;
				min-height: 53px;
			}

			.saveButton {
			}
		}

		.cardSaveImageMobile {
			width: 60%;
			height: auto;
		}
	}
}
