@import 'src/master';

.checkmark {
	display: flex;

	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 5px solid white;
	&.hoverable:hover {
		border: 5px groove $turkoosi;
	}

	&.green {
		background-color: $color-green;
	}
	&.yellow {
		background-color: $color-yellow;
	}
	&.red {
		background-color: $color-red;
	}
	.white {
		color: #00000080 !important;
	}

	align-items: center;
	justify-content: space-around;

	&.checked {
		border: 5px groove $turkoosi;

		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	}
}

@media all and (max-width: 750px) {
	.checkmark {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		&.green {
			background-color: $color-green;
		}
		&.yellow {
			background-color: $color-yellow;
		}
		&.red {
			background-color: $color-red;
		}
		.checkedMark {
			display: block;
			height: 50px;
			width: 50px;
		}
	}
}
