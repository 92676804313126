@import 'src/master';

.questionnaireWrapper {
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $vaaleanharmaa;

	margin: 0 0 0 0;

	.sectionContainer {
		width: 100%;

		background-color: $color-background-main;
	}
	.sectionContainer:nth-of-type(n + 2) {
		margin-top: 20px;
	}
	.endQuestionnaireButtonWrapper {
		margin-top: 20px;
		width: 100%;
		padding: 40px 0 40px 0;
		display: flex;
		align-content: center;
		justify-content: center;
		.endQuestionnaireButton {
			height: 50px;
			width: 250px;
			font-size: 20px;
			background-color: $color-button-primary;
			&:hover,
			&:active {
				background-color: $color-button-primary;
			}
		}
	}
}

.questionnaireWrapperMobile {
	width: 100%;
	display: flex;
	flex-direction: column;
	.react-swipeable-view-container {
		width: 100%;
		min-height: 50vh;
		.questionWrapperMobile {
			width: 100%;
			height: 100%;
		}
	}
	.questionWrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		.ageAndNameContainer {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			padding: 5vh 0 5vh 0;
			width: 90%;
			margin-top: 20px;
			align-items: center;
			min-height: 30vh;
			.nameContainer {
				margin-bottom: 20px;
			}
			.textfieldContainer {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				.text-field-name {
					width: 60vw;
				}
				.text-field-age {
					width: 20vw !important;
				}
				@media all and (max-width: 750px) {
					.text-field-age {
						width: unset;
					}
				}
			}
			.neuvokasQuestion {
				color: $saavutettavaTurkoosi !important;
				font-weight: bold !important;
			}

			.textInput:after {
				border-bottom-color: $turkoosi !important;
			}
		}

		.adultNamesContainer {
			margin-top: 20px;
			width: 90%;
			flex-grow: 3;
		}
	}
	.panelWrapper {
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		height: 100%;

		.panel {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			padding: 2vh 0 5vh 0;
			text-align: left;
			align-items: center;
			margin-top: 20px;
			height: 25vh;
			width: 90vw;

			.endQuestionnaireButton {
				font-size: 1em !important;
			}
		}
		.buttonContainer {
			margin-top: 20px;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-evenly;
			width: 100%;
		}
	}
	.swipeButtonContainer {
		margin-top: 20px;
		margin-bottom: 20px;

		display: flex;
		width: 100%;
		justify-content: space-between;

		.leftButtonWrapper,
		.rightButtonWrapper {
			display: flex;
			flex-grow: auto;
		}
		.swipeButtonLeft {
			align-self: flex-start;
			margin-left: 10vw;
		}
		.swipeButtonRight {
			align-self: flex-end;
			margin-right: 10vw;
		}
	}
}
.swipeButtonRight,
.swipeButtonLeft {
	border-color: $AAturkoosi !important;

	color: $AAturkoosi !important;
	font-weight: bold;
}
