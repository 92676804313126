@import 'src/master';

.schoolCardSelection {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.cardSelectionImage {
		height: auto;
		width: 70%;
		margin-bottom: 50px;
	}

	.neuvokasText {
		width: 70%;
		text-align: left;
		margin-bottom: 20px;
	}

	.neuvokasButton {
		margin: 30px;
		width: 400px !important;
	}
	.starButton {
		margin-bottom: 50px;
	}
	.starWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 20px;
	}
}

@media all and (max-width: 750px) {
	.schoolCardSelection {
		background-color: white;
		padding: 0px 0 20px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.cardSelectionImage {
			width: 100%;
			margin-bottom: 20px;
		}

		.neuvokasText {
			width: 90%;
			text-align: left;
			margin-bottom: 20px;
		}

		.neuvokasText {
			width: 90vw;
			text-align: left;
			margin-bottom: 20px;
		}

		.neuvokasButton {
			margin: 30px;
			width: 80vw !important;
		}
		.starButton {
			margin-bottom: 50px;
		}
		.starWrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 20px;
		}
	}
}
